import React from "react";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";

const IslandsMap = () => {
  useRedirectHandler(() => {
    return `https://student.classdojo.com/#/home/islands`;
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default IslandsMap;
